import axios from 'axios'
import { Notification, MessageBox, Message,Loading } from 'element-ui'

import { getToken,removeToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import router from '@/router'
import {blobValidate, tansParams} from "../../../gcsbWeb/src/utils/ruoyi";
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

import { saveAs } from 'file-saver'

let downloadLoadingInstance;
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {

    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?';
        for (const propName of Object.keys(config.params)) {
            const value = config.params[propName];
            var part = encodeURIComponent(propName) + "=";
            if (value !== null && typeof(value) !== "undefined") {
                if (typeof value === 'object') {
                    for (const key of Object.keys(value)) {
                        if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                            let params = propName + '[' + key + ']';
                            let subPart = encodeURIComponent(params) + '=';
                            url += subPart + encodeURIComponent(value[key]) + '&';
                        }
                    }
                } else {
                    url += part + encodeURIComponent(value) + "&";
                }
            }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode['default']

        if (code === 401) {

            if(window.location.getItem('gcsbUserToken')){

            MessageBox.alert('登录状态已过期，请重新登录！', '系统提示', {
                    confirmButtonText: '确定',
                    callback: () => {
                        const url =encodeURIComponent(window.location.href);
                        window.location.href=process.env.VUE_APP_BASE_SSO+"/LoginRegister?redirect="+url+'&type=link';
                        // location.href =process.env.VUE_APP_BASE_SSO+'/LoginRegister';
                        // location.href =router.options.base;

                    }
                }
            )
            }
            removeToken();
            // return Promise.reject('令牌验证失败')
        } else if (code === 500) {
            if(msg&& (msg.indexOf('登录失败')==-1 && msg.indexOf('token')==-1 )){
                Message({
                    message: msg,
                    type: 'error'
                })
            }
            return Promise.reject(new Error(msg))
        } else if (code === 999) {
            removeToken();
            MessageBox.alert(msg, '系统提示', {
                    confirmButtonText: '知道了',
                    callback: () => {
                        window.location.href=process.env.VUE_APP_BASE_SSO+"/EngineersInstitute/MasterDoctor?title=工程硕博士"
                        // window.history.back();
                        // const url =encodeURIComponent(window.location.href);
                        // window.location.href=process.env.VUE_APP_BASE_SSO+"/LoginRegister?redirect="+url+'&type=link';
                        // location.href =process.env.VUE_APP_BASE_SSO+'/LoginRegister';
                        // location.href =router.options.base;

                    }
                }
            )
            // return Promise.reject('令牌验证失败')
        }else if (code !== 200) {
            Notification.error({
                title: msg
            })
            return Promise.reject('error')
        } else {
            return res.data
        }
    },
    error => {
        console.log('err' + error)
        let { message } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        }
        else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        }
        else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({
            message: message,
            type: 'error',
            duration: 3 * 1000
        })
        return Promise.reject(error)
    }
)

// 通用下载方法
export function download(url, params, filename, config) {
    downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
    return service.post(url, params, {
        transformRequest: [(params) => { return tansParams(params) }],
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
        ...config
    }).then(async (data) => {
        const isLogin = await blobValidate(data);
        if (isLogin) {
            const blob = new Blob([data])
            saveAs(blob, filename)
        } else {
            const resText = await data.text();
            const rspObj = JSON.parse(resText);
            const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
            Message.error(errMsg);
        }
        downloadLoadingInstance.close();
    }).catch((r) => {
        console.error(r)
        Message.error('下载文件出现错误，请联系管理员！')
        downloadLoadingInstance.close();
    })
}

export default service
